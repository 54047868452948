// .bg-image-2 {
//   background-image: url("../assets/img/bg-image-2.png");
// }

.sidenav {
  @apply bg-mdsecondary bg-no-repeat bg-right-bottom bg-auto;
  // background-image: url("../assets/img/bg-image-2.png");
  width: 115px;

  &-nav-item {
    @apply border-transparent border-r-4;
    @apply px-5 py-6;
    @apply flex items-center;
    @apply font-semibold text-white tracking-wide;
    @apply transition ease-in-out duration-500;

    &:hover {
      @apply bg-lighter;
    }
  }
}

.sidenav-active {
  @apply border-transparent border-r-4;
  @apply px-5 py-6;
  @apply flex items-center;
  @apply font-semibold text-white tracking-wide;
  @apply transition ease-in-out duration-500;
  @apply border-lighter text-lighter;
}

@screen md {
  .sidenav {
    width: 150px;
  }
}

.form-group {
  &__label {
    @apply block text-sm leading-5 text-gray-600 mb-1;
  }

  &--light {
    .form-group__label {
      @apply text-white;
    }
  }

  &.has-error {
    .form-control,
    .react-select__control {
      @apply border-red-600;
    }

    .form-group__label {
      @apply text-red-600;
    }
  }
}

.heading {
  &-a {
    @apply text-primary font-bold text-2xl tracking-wide mb-3;
  }

  &-b {
    @apply text-primary font-bold text-3xl tracking-wide;
  }

  &-c {
    @apply text-primary font-bold text-lg tracking-wide;
  }
}

.text {
  &-a {
    @apply text-gray-600;
  }

  &-b {
    @apply text-primary font-bold;
  }

  &-c {
    @apply text-secondary;
  }

  &-d {
    @apply text-primary;
  }
}

.link {
  &-a {
    @apply text-primary font-bold;
  }

  &-b {
    @apply text-secondary;
  }
}

.container {
  &-a {
    @apply pt-8 pb-4;
  }
}

.react-datepicker-wrapper {
  @apply block;
}

.organisation-icon {
  @apply font-semibold text-primary;
  cursor: pointer;
}

.xero-button {
  height: 43px;
}

.modal-lg {
  min-width: 60%;
}
