@tailwind base;

a {
  @apply text-blue-600;
}

th {
  @apply text-left;
}

hr {
  @apply my-4;
}

@tailwind components;
@tailwind utilities;
@import "bootstrap.scss";
@import "components.scss";
@import "nav.scss";

body,
html {
  @apply bg-gray-50 text-gray-700;
}

.nav-tabs {
  @apply border-b-0 cursor-pointer;

  .nav-link {
    @apply border-0 border-b-2 border-transparent text-sm font-normal text-primary;
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    @apply font-bold text-mred border-lighter;
  }
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
