$modul-primary: #cd071e;
$modul-secondary: #323232;
$modul-three: #405584;
$theme-colors: (
  "primary": $modul-primary,
  "secondary": $modul-secondary,
  "mdblue": $modul-three,
);

$grid-breakpoints: (
  xs: 0,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
);
$link-color: $modul-primary;
$font-family-sans-serif:
  Roboto,
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  "Helvetica Neue",
  Arial,
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

//$theme-colors: map-remove($theme-colors, "secondary");
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/input-group";

.container {
  padding-left: 0;
  padding-right: 0;
}

.btn-link {
  &:focus {
    @apply shadow-none;
  }
}

.btn-mdsecondary {
  @apply text-white;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus,
a.text-success:hover {
  color: #1e7e34 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #bd2130 !important;
}
